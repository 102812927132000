<template>
    <div class="test-box-d">
         <el-breadcrumb separator="/">
            <el-breadcrumb-item>报告考评</el-breadcrumb-item>
            <el-breadcrumb-item>考评</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item>
                     <el-button round @click="drawer=true" id="addBtn" type="primary" size="small" icon="el-icon-plus">添加</el-button>
                </el-form-item>
                <el-form-item>
                    <el-autocomplete
                            class="inline-input"
                            v-model="staff"
                            :fetch-suggestions="staffSearch"
                            placeholder="搜索工程师"
                            @select="staffSelect"
                     ></el-autocomplete>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="client" placeholder="客户"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="riskType" placeholder="选择风险类型">
                        <el-option
                        v-for="item in riskTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="risk" placeholder="选择风险类型">
                        <el-option
                        v-for="item in riskOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                    v-model="timeArr"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="refresh" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
                <el-form-item v-if="exportRule">
                    <el-dropdown split-button size="mini" @command="handleClick" v-loading="loading">
                        评分
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="0">快速重新评分</el-dropdown-item>
                            <el-dropdown-item command="1">下载评分</el-dropdown-item>
                        </el-dropdown-menu>
                        </el-dropdown>
                    <!-- <el-button round plain @click="download" type="success" size="small" icon="el-icon-download">导出</el-button> -->
                </el-form-item>
                <!-- <el-form-item v-if="exportRule">
                <el-button type="text" @click="download(true)">快速重新评分</el-button>
                </el-form-item> -->
            </el-form>
        </div>
        <!-- content -->
        <div class="box-contain" v-loading="loading">
            <el-table
            border
            :data="list">
                <el-table-column
                width="100px"
                    label="日期">
                    <template slot-scope="scope">
                        {{scope.row.start|dateFormat}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="client"
                    width="100px"
                    label="客户">
                </el-table-column>
                <el-table-column
                    prop="type"
                    width="80px"
                    label="服务类型">
                </el-table-column>
                <el-table-column
                    width="80px"
                    label="服务时间">
                    <template slot-scope="scope">
                        {{scope.row.start|timeRangeFormat(scope.row.end)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="staff"
                    width="80px"
                    label="员工">
                </el-table-column>
                <el-table-column
                    prop="type"
                    width="100px"
                    label="评分">
                    <template slot-scope="scope">
                        <span v-if="!exportRule">{{scope.row.score}}</span>
                        <el-input v-else v-model="scope.row.score" style="width:60px"
                         type="number" placeholder="请输入评分" size="mini" :max="150"
                         @change="grade(scope.$index)"></el-input>
                         <i class="el-icon-question" @click="showProcess(scope.row)" />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="mouseRisk"
                    width="50px"
                    label="鼠害风险">
                </el-table-column>
                <el-table-column
                    prop="roachRisk"
                    width="50px"
                    label="蟑螂风险">
                </el-table-column>
                <el-table-column
                    prop="flyRisk"
                    width="50px"
                    label="飞虫风险">
                </el-table-column>
                <el-table-column
                    prop="otherRisk"
                    width="50px"
                    label="其它虫害风险">
                </el-table-column>
                <el-table-column
                    prop="summary"
                    width="200px"
                    label="服务总结">
                </el-table-column>
                <el-table-column
                    width="100px"
                    label="上次整改行动">
                    <template slot-scope="scope">
                        {{scope.row.lastAdList.length}}条,
                        <span class="view" @click="viewAd(scope.row)">查看</span>
                    </template>
                </el-table-column>
                <el-table-column
                    width="100px"
                    label="全面检查">
                    <template slot-scope="scope">
                        {{scope.row.actionList.length}}条,
                        <span class="view" @click="viewAction(scope.row)">查看</span>
                    </template>
                </el-table-column>
                <el-table-column
                    width="100px"
                    label="设备巡检">
                    <template slot-scope="scope">
                        {{scope.row.deviceList.length}}个,
                        <span class="view" @click="viewDevice(scope.row)">查看</span>
                    </template>
                </el-table-column>
                <el-table-column
                    width="100px"
                    label="防治措施">
                    <template slot-scope="scope">
                        {{scope.row.moreAction.length}}条,
                        <span class="view" @click="viewMeasure(scope.row)">查看</span>
                    </template>
                </el-table-column>
                <el-table-column
                    width="100px"
                    label="拆机数量">
                    <template slot-scope="scope">
                        {{scope.row.disassembly}}（台）设备
                    </template>
                </el-table-column>
                <el-table-column
                    width="100px"
                    label="拆机设备描述">
                    <template slot-scope="scope">
                        {{scope.row.descDisassembly||'无'}}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    width="100px"
                    label="操作">
                    <template slot-scope="scope">
                        <i @click="del(scope.row.id)" title="删除" class="el-icon-delete i-btn"></i>
                        <i class="el-icon-aim i-btn" title="计算更新评分" @click="refreshScore(scope.row)"/>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- page分页 -->
        <div class="pageNav" v-if="list.length>=1">
            <el-pagination
                hide-on-single-page
                v-model="page"
                layout="prev, pager, next"
                @current-change="currentChange"
                :page-count="totalPage">
            </el-pagination>
            <el-dropdown>
                <span class="el-dropdown-link">
                    每页显示 {{pageSize}} 条<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="choisePageSize(item)" v-for="item in pageArr" :key="item">
                        {{item}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <!-- editor -->
         <el-dialog title="粘贴内容"  append-to-body
        :visible.sync="drawer">
            <vue-ueditor-wrap v-model="content" :config="myConfig"></vue-ueditor-wrap>
            <div class="btn-box" v-loading="loading">
                 <el-button type="success" @click="saveRemark" plain :disabled="loading">
                    确定</el-button> 
            </div>
        </el-dialog>
        <!-- 整改行动 -->
        <el-dialog append-to-body
        v-loading="loading"
        element-loading-text="合成图片中..."
        element-loading-spinner="el-icon-loading"
        :visible.sync="actionDrawer">
            <div class="slot" slot="title" >
                <span class="titleSpan">上次整改行动</span>
                <el-button @click="saveImg('lastAction')" type="success" size="mini" icon="el-icon-download" circle></el-button>
            </div>
            <div class="con-box" ref="lastAction">
                <LastAdviceList :data="currentAdvise"/>
            </div>
        </el-dialog>
        <!-- actionList -->
        <el-dialog append-to-body
        width="60%"
        v-loading="loading"
        element-loading-text="合成图片中..."
        element-loading-spinner="el-icon-loading"
        :visible.sync="actionListDrawer">
            <div class="slot" slot="title" v-if="!loading">
                <span class="titleSpan">全面检查</span>
                <el-button @click="saveImg('imageCheckAll')" type="success" size="mini" icon="el-icon-download" circle></el-button>
            </div>
            <div class="con-box" ref="imageCheckAll">
            <ActionList :waiting="loading" :data="currentAdvise"/>
            </div>
        </el-dialog>
        <!-- actionList -->
        <el-dialog append-to-body
        width="60%"
        v-loading="loading"
        element-loading-text="合成图片中..."
        element-loading-spinner="el-icon-loading"
        :visible.sync="deviceDrawer">
            <div class="slot" slot="title" >
                <span class="titleSpan">设备巡检</span>
                <el-button @click="saveImg('imageDevice')" type="success" size="mini" icon="el-icon-download" circle></el-button>
            </div>
            <div class="con-box" ref="imageDevice">
                <deviceList :data="currentAdvise"/>
            </div>
        </el-dialog>
        <!-- actionList -->
        <el-dialog append-to-body
        width="60%"
        v-loading="loading"
        element-loading-text="合成图片中..."
        element-loading-spinner="el-icon-loading"
        :visible.sync="measureDrawer">
        <div class="slot" slot="title" >
            <span class="titleSpan">防治措施</span>
            <el-button @click="saveImg('imageTofile')" type="success" size="mini" icon="el-icon-download" circle></el-button>
        </div>
            <div class="con-box" ref="imageTofile">
                <measureList @readyToDnd="saveImg('imageTofile')" :download="downloadStatus" :data="currentAdvise"/>
            </div>
        </el-dialog>
    </div>
</template>
<script>
// saveRemark
import checkPointList from './neecheck.json';
import {exportExcel} from '@/utils/excel.js'
import html2canvas from "html2canvas";
import VueUeditorWrap from 'vue-ueditor-wrap'
import {formatTime,getNextDate,GetDateDiff} from '@/utils/util.js'
import LastAdviceList from '../../components/LastAdviceList.vue'
import ActionList from '../../components/ActionList.vue'
import deviceList from '../../components/deviceList.vue'
import measureList from '../../components/measureList.vue'
export default {
    components:{
        VueUeditorWrap,
        LastAdviceList,
        ActionList,
        deviceList,
        measureList
    },
    data(){
        return{
            DisassemblyList:['裹粉','腌制','炸炉','煎炉','汽水','可乐','圣代','雪糕','新地','烘包','冰柜','冰箱','制冰','薯条','糖浆','滤水','消防'],
            downloadStatus:false,//导出图片
            measureDrawer:false,//防治措施
            addTaskRule:true,//添加编辑任务权限
            currentAdvise:{},
            deviceDrawer:false,//设备检查
            actionDrawer:false,//整改行动
            actionListDrawer:false,//全面检查
            content:"",
            drawer:false,
            exportRule:true,//导出权限,
            loading:true,
            data:[],
            client:"",//客户搜索
            form:{},
            staff:'',
            task:{},//任务
            list:[],
            risk:"",//风险
            riskOptions:[{
                value: 'A',
                label: 'A'
            },{
                value: 'B',
                label: 'B'
            },{
                value: 'C',
                label: 'C'
            },{
                value: 'R',
                label: 'R'
            }],
            riskType:"",//风险类型
            riskTypeOptions:
            [{
                value: 'mouse',
                label: '老鼠'
            },{
                value: 'roach',
                label: '蟑螂'
            },{
                value: 'fly',
                label: '飞虫'
            }],
            page:1,
            pageArr:[6,10,15,20],
            totalPage:1,
            pageSize:10,
            start:"",
            end:"",
            timeArr:[],
            myConfig: {
                // 编辑器不自动被内容撑高
                serverparam:{
                    method:"ueditorUpload"
                },
                toolbars: [
                [
                    "fullscreen",
                    "source",
                    "|",
                    "undo",
                    "redo"
                ]
                ],
                autoHeightEnabled: false,
                maximumWords:500000,
                // 初始容器高度
                initialFrameHeight: 200,
                // 初始容器宽度
                initialFrameWidth: '100%',
                // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
                serverUrl: "",
                baseUrl:"",
                imageActionName:"",
                // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
                UEDITOR_HOME_URL: '/UEditor/'
            }
        }
    },
    created(){
        if(this.user&&this.user.token){
            this.myConfig.serverUrl=this.API.api+'?token='+this.user.token
            this.myConfig.baseUrl=this.API.apiUrl
            //console.log(this.myConfig.baseUrl) 
         this.actionUrl=this.actionUrl+'?token='+this.user.token
        }
        
        //向parent发送消息
        window.parent.postMessage('Hello from iframe', '*');
    },
    mounted(){
        let that = this
        window.addEventListener('message', (event) => {
            //console.log(event,event.origin)
            //window.alert(JSON.stringify(event.data))
            if (event.origin !== 'https://bo.yhipm.cn'&&event.origin!== "http://localhost:8080") return;
                //console.log('Message received from parent:', event.data);
                that.user = event.data.data
                console.log(that.user)
                sessionStorage.setItem("user",JSON.stringify(that.user))
                that.myConfig.serverUrl=that.API.api+'?token='+that.user.token
                that.myConfig.baseUrl=that.API.apiUrl
                console.log(this.myConfig.baseUrl) 
                that.actionUrl=that.actionUrl+'?token='+that.user.token
                //that.loginToken(event.data)
                that.search()
            }
        );
    },
    watch:{
        $route:{
            immediate:true,
            handler(){
                let now = formatTime(new Date) 
                ,str = now.split(" ")[0]
                let start = str.split("-"),
                start_time = `${start[0]}/${start[1]}/01 00:00:00`
                //console.log('start',start_time,new Date(start_time).getTime(),new Date(new Date().getTime()+24*60*60*1000))
                this.timeArr=[new Date(start_time),new Date(new Date().getTime()+24*60*60*1000)]
                this.user = JSON.parse(sessionStorage.getItem("user"))
                //this.search()
            }
        }
	},
    methods:{
        //获取token以自动登陆
        loginToken(data){
            this.user = data
            this.search()
        },
        showProcess(row){
            console.log(row)
            this.$message({
                    message: row.cal_process,
                   type: 'warning'
                });
        },
        //更改评分
        grade(index){
            //console.log(index,this.list[index].score)
            this.loading=true
            let params = {
                method:"updateMCgrade",
                mid:this.list[index].id,
                score:this.list[index].score
            }
            this.$ajax.post(this.API.api,params).then(res=>{
                console.log(res)
                this.loading=false
                 //刷新
                 this.refresh()
            }).catch(err=>{
                this.loading = false 
                this.$message({
                    message: err.msg||this.CONST.DEL_FAILD,
                   type: 'warning'
                });
            })
        },
        //按钮评分选择
        handleClick(e){
            let save = parseInt(e)==1?false:true
            console.log(e,save)
            this.download(save)
        },
        //刷新某行评分数据
        refreshScore(row){
            this.downloadTest([row],true)
        },
        downloadListImg(){
            this.loading=false
            this.downloadStatus = true
        },
        async saveImg(ref) {
            //useCORS: true, 
            this.loading=true
            const opts = {useCORS: true,imageTimeout:0}
            setTimeout(async()=>{
                const canvas = await html2canvas(this.$refs[ref], opts)
                this.downloadImg(canvas.toDataURL("image/png"))
            })
            
        },
        //选择每页显示数量
        choisePageSize(e){
            this.pageSize = e 
            this.search()
        },
        //选择页码
        currentChange(e){
           // console.log(e)
           this.page = e
           this.search()
        },
        downloadImg(url){
            // 如果是在网页中可以直接创建一个 a 标签直接下载 
            let a = document.createElement('a')
            a.href = url
            a.download = '首页截图'
            a.click()
            this.loading=false
            
        },
        viewMeasure(row){
            this.currentAdvise = row 
            this.measureDrawer = true
        },
        viewDevice(row){
            this.currentAdvise = row 
            this.deviceDrawer = true
        },
        del(id){
            if(!this.addTaskRule){
                this.$message({
                    message: "权限不足！",
                    type: 'warning'
                });
                return
            }
            this.$confirm(this.CONST.DEL_CONFIRM, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    let params = {
                        method:'delMCReport',
                        id
                    }
                    this.loading=true
                    //console.log(params)
                    this.$ajax.post(this.API.api,params).then(res=>{
                        console.log(res)
                        //this.loading=false
                        //刷新
                        this.refresh()
                        this.$message({
                            message: res.msg||this.CONST.DEL_SUC,
                            type: 'success'
                        });
                    }).catch(err=>{
                        console.log(err)
                        this.loading = false 
                        this.$message({
                            message: err.msg||this.CONST.DEL_FAILD,
                            type: 'warning'
                        });
                    })

            }).catch(err=>{
                console.log(err)
            })
        },
        viewAd(data){
            console.log(data)
            this.currentAdvise = data 
            this.actionDrawer = true
        },
        viewAction(data){
            this.currentAdvise = data 
            this.actionListDrawer = true
        },
        inputChange(str){
            return str.replace(/[\r\n]/g,"")
        },
        getCheckPoint(item){
            //checkPointList
            console.log(checkPointList,"___checkPointList",item)
            // let json = arr.filter(function(p){ 
            //     return  item.firstPlace.indexOf(p.first)!==-1;
            // });
            // console.log(json)
        },
        saveRemark(){
            //console.log(this.content)
            try {
            let that = this
            let splitOne = this.content.split("<p>*上次服务餐厅整改行动检查")
            //console.log(splitOne[0])
            let headerInfo = splitOne[0].split("*本次服务类型")
            let mainInfo = headerInfo[0].split("</p>")
           // console.log(mainInfo)
            mainInfo.forEach((item)=>{
                let arr = item.split("</span>")
                let title = this.getHtmlPlainText(arr[0])
                //console.log(title)
                if(title=="防治餐厅:"){
                    this.task.client = this.getHtmlPlainText(arr[1])
                }else if(title=="服务人员:"){
                    let staff = this.getHtmlPlainText(arr[1]).split("--")
                    this.task.staff = staff[0]
                }else if(title=="开始时间:"){
                    this.task.start = this.getHtmlPlainText(arr[1])
                }else if(title=="结束时间:"){
                    this.task.end = this.getHtmlPlainText(arr[1])
                }else if(title=="服务类型:"){
                    this.task.type = this.getHtmlPlainText(arr[1])
                }
            })
            let mtime = this.task.end.split(" "),etime=this.task.start.split(" ")
            let date = mtime[0].split("-")
            let mday = date[1]+'-'+date[2]
            //console.log(mtime[0]+' '+etime[1])
            //console.log('mday:'+mday,'etime[0]'+etime[0],'mtime:'+mtime,'timeStart:'+timeStart,'timeEnd:'+timeEnd)
            if(mday!=etime[0]){
                //console.log(parseInt(date[1]),parseInt(date[2]))
                //不是同一天，获取开始日期
                //let startArray=etime.split("-")
                if(parseInt(date[1])===1&&parseInt(date[2])===1){
                    //1月1日
                    this.task.start = getNextDate(mtime[0],-1) +' '+etime[1]
                }else{
                    this.task.start = getNextDate(mtime[0],-1) +' '+etime[1]
                }
                //console.log(this.task.start)
            }else{
                this.task.start = mtime[0]+' '+etime[1]
            }
            //服务类型
            let fuwu = headerInfo[1].split("*上次服务餐厅整改行动检查")
            let typeString = fuwu[0].split("<p>")
            console.log(typeString)
            typeString.forEach(item=>{
                let stringitem  = item.split("</span>")
                if(that.getHtmlPlainText(stringitem[0])=="本次服务类型:"){
                    that.task.type = stringitem[1]?that.getHtmlPlainText(stringitem[1]):''
                }
                //console.log(this.getHtmlPlainText(stringitem[0]))
            })

            //获取第二段内容
            //console.log(splitOne[1])
            let secondContent = !splitOne[1]?headerInfo[1].split("*上门间隔期间虫害状况沟通"):splitOne[1].split("*上门间隔期间虫害状况沟通")
            //console.log(secondContent)
            //上次服务建议是否执行
            let lastAdivce = secondContent[0].split("</p>")
            //console.log(lastAdivce)
            let lastAdivceList=[];//整改行动列表
            let listItem={}
            lastAdivce.forEach(item=>{
                let mitem = item.split("</span>")
                let title = that.getHtmlPlainText(mitem[0])
                //console.log(title)
                //console.log(that.getHtmlPlainText(mitem[0]))
                if(title&&title=="一级区域:"){
                    listItem.firstPlace = that.getHtmlPlainText(mitem[1])
                }else if(title=="二级区域:"){
                    listItem.secondPlace = that.getHtmlPlainText(mitem[1])
                }else if(title=="风险点:"){
                    listItem.risk = that.getHtmlPlainText(mitem[1])
                }else if(title=="上传图片:"){
                    lastAdivceList.push(listItem)
                    listItem={}
                }else if(title=="风险点描述:"){
                   listItem.riskDesc = that.getHtmlPlainText(mitem[1])
                }else if(title=="整改行动项目:"){
                    listItem.action = that.getHtmlPlainText(mitem[1])
                }else if(title=="是否完成:"){
                    listItem.isDone = that.getHtmlPlainText(mitem[1])
                }else if(title=="连续发生次数:"){
                    listItem.nums = that.getHtmlPlainText(mitem[1])
                }else if(title=="建议完成人:"){
                    listItem.completedBy = that.getHtmlPlainText(mitem[1])
                }else if(title=="备注:"){
                    listItem.remark = that.getHtmlPlainText(mitem[1])
                }
            })
            this.task.lastAdivceList = lastAdivceList

            //全面检查 *餐厅全面检查
            //console.log(secondContent[1])
            let datas = !splitOne[1]?headerInfo[1].split("*餐厅全面检查"):secondContent[1].split("*餐厅全面检查")
            let checkData = datas[1].split("*虫害防治设备检查")
            let dataArray = checkData[0].split('<span style="box-sizing: border-box; font-size: 16px;">一级')
            //console.log(dataArray)
            let actionList=[];//整改行动列表
            dataArray.forEach(it=>{
                listItem={
                    images:[]
                }
                if(it){
                    let itemData = it.split("</p>"),length=itemData.length
                    //console.log(itemData,'imgdata')
                    itemData.forEach((item,index)=>{
                        let mitem = item.split("</span>")
                        let title = that.getHtmlPlainText(mitem[0])
                        if(item.indexOf("<img")!==-1){
                            //let imgStrs = item.match(/<img.*?>/g);
                            //console.log(item.match(/\ssrc=['"](.*?)['"]/)[1])
                            if(item.indexOf("src=")!==-1){
                                //有图片
                                let url = item.match(/\ssrc=['"](.*?)['"]/)[1]
                                if(!listItem.images){
                                    listItem.images=[]
                                }
                                listItem.images.push(url)
                            }
                            
                        }else{
                            if(title&&title=="区域:"){
                                listItem.firstPlace = that.getHtmlPlainText(mitem[1])
                            }else if(title=="二级区域:"){
                                listItem.secondPlace = that.getHtmlPlainText(mitem[1])
                            }else if(title=="风险类型:"){
                                listItem.risk = that.getHtmlPlainText(mitem[1])
                            }else if(title=="具体情况:"){
                                listItem.riskDesc = that.getHtmlPlainText(mitem[1])
                            }else if(title=="具体数量:"){
                                listItem.nums = that.getHtmlPlainText(mitem[1])
                            }else if(title=="虫害来源:"){
                                listItem.source = that.getHtmlPlainText(mitem[1])
                            }else if(title=="描述:"){
                                listItem.remark = that.getHtmlPlainText(mitem[1])
                            }
                        }
                        if(index==(length-1)&&listItem.firstPlace){
                            //完成
                            //console.log('完成')
                            actionList.push(listItem)
                        }
                    })
                }
            })
            this.task.actionList = actionList

            //虫害风险判定
            let riskData = checkData[1].split("*虫害风险等级评定")
            let riskArray = riskData[1].split("</p>"),risk={}
            //console.log(riskArray)
            riskArray.forEach(item=>{
                let mitem = item.split("</span>")
                let title = that.getHtmlPlainText(mitem[0])
                //console.log(title)
                if(title=="鼠类风险级别:"){
                    risk.mouseRisk=that.getHtmlPlainText(mitem[1])
                }else if(title=="蟑螂风险级别:"){
                    risk.roachRisk=that.getHtmlPlainText(mitem[1])
                }else if(title=="飞虫风险级别:"){
                    risk.flyRisk=that.getHtmlPlainText(mitem[1])
                }else if(title=="其它虫害风险级别:"){
                    risk.otherRisk=that.getHtmlPlainText(mitem[1])
                }else if(title=="服务总结:"){
                    that.task.summary=that.getHtmlPlainText(mitem[1])
                }
            })
            this.task.risk = risk

            //虫害设备巡检
            let deviceArray = riskData[0].split("*虫害控制措施")
            let deviceList = deviceArray[0].split('<span style="box-sizing: border-box; font-size: 16px;">设备名')
            //console.log(deviceList)
            let devList=[];//整改行动列表
            deviceList.forEach(it=>{
                //console.log(it)
                listItem={
                    bugs:{},
                    images:[]
                }
                if(it){
                    let itemData = it.split("</p>"),length=itemData.length
                    //console.log(itemData,'imgdata')
                    itemData.forEach((item,index)=>{
                        //console.log(item)
                        let mitem = item.split("</span>")
                        let title = that.getHtmlPlainText(mitem[0])
                        if(item.indexOf("<img")!==-1){
                            if(item.indexOf("src=")!==-1){
                                //有图片
                                let url = item.match(/\ssrc=['"](.*?)['"]/)[1]
                                if(!listItem.images){
                                    listItem.images=[]
                                }
                                listItem.images.push(url)
                            }
                            
                        }else{
                            if(title=="称:"){
                                listItem.name=that.getHtmlPlainText(mitem[1])
                            }else if(title=="区域:"){
                                listItem.place=that.getHtmlPlainText(mitem[1])
                            }else if(title=="设备编号:"){
                                listItem.num=that.getHtmlPlainText(mitem[1])
                            }else if(title=="设备状态:"){
                                listItem.status=that.getHtmlPlainText(mitem[1])
                            }else if(title=="耗材是否更换:"){
                                listItem.useMaterial=that.getHtmlPlainText(mitem[1])
                            }else if(title=="鼠类数量:"){
                                listItem.bugs.mouse=that.getHtmlPlainText(mitem[1])
                            }else if(title=="蟑螂数量:"){
                                listItem.bugs.roach=that.getHtmlPlainText(mitem[1])
                            }else if(title=="苍蝇数量:"){
                                listItem.bugs.fly=that.getHtmlPlainText(mitem[1])
                            }else if(title=="蚊子数量:"){
                                listItem.bugs.mozzie=that.getHtmlPlainText(mitem[1])
                            }else if(title=="果蝇/蚤/蝇蛾/蠓数量:"){
                                listItem.bugs.otherFly=that.getHtmlPlainText(mitem[1])
                            }else if(title=="园林昆虫数量:"){
                                listItem.bugs.treesFly=that.getHtmlPlainText(mitem[1])
                            }else if(title=="其它昆虫数量:"){
                                listItem.bugs.otherBugs=that.getHtmlPlainText(mitem[1])
                            }else if(title=="备注:"){
                                listItem.remark=that.getHtmlPlainText(mitem[1])
                            }
                        }
                        if(index==(length-1)&&listItem.name){
                            //完成
                            //console.log('完成')
                            devList.push(listItem)
                        }
                    })
                }
            })
            //console.log(devList)
            //合并数据
            that.task.deviceList = devList
            //虫害防治措施
            //console.log(deviceArray[1])
            let moreActionStr = deviceArray[1].split("*整改行动汇总")
            //console.log(moreActionStr[0])
            let actionArr = moreActionStr[0].split('<span style="box-sizing: border-box; font-size: 16px;">标靶')
            //console.log(actionArr)
            let moreAction=[];//整改行动列表
            actionArr.forEach(it=>{
                //console.log(it)
                listItem={
                    images:[]
                }
                if(it){
                    let itemData = it.split("</p>"),length=itemData.length
                    //console.log(itemData,'imgdata')
                    itemData.forEach((item,index)=>{
                        //console.log(item)
                        let mitem = item.split("</span>")
                        let title = that.getHtmlPlainText(mitem[0])
                        //console.log(title)
                        if(item.indexOf("<img")!==-1){
                            if(item.indexOf("src=")!==-1){
                                //有图片
                                let url = item.match(/\ssrc=['"](.*?)['"]/)[1]
                                if(!listItem.images){
                                    listItem.images=[]
                                }
                                listItem.images.push(url)
                            }
                            
                        }else{
                            if(title=="害虫:"){
                                listItem.target=that.getHtmlPlainText(mitem[1])
                            }else if(title=="施用物料:"){
                                listItem.material=that.getHtmlPlainText(mitem[1])
                            }else if(title=="稀释比例:"){
                                listItem.proportioning=that.getHtmlPlainText(mitem[1])
                            }else if(title=="单位:"){
                                listItem.unit=that.getHtmlPlainText(mitem[1])
                            }else if(title=="具体数量:"){
                                listItem.usage=that.getHtmlPlainText(mitem[1])
                            }else if(title=="应用方法:"){
                                listItem.usageType=that.getHtmlPlainText(mitem[1])
                            }else if(title=="服务位置:"){
                                listItem.place=that.getHtmlPlainText(mitem[1])
                            }
                        }
                        if(index==(length-1)&&listItem.target){
                            //完成
                            //console.log('完成')
                            moreAction.push(listItem)
                        }
                    })
                }
            })
            //console.log(moreAction)
            this.task.moreAction = moreAction
            } catch (error) {
                this.$message({
                            message: error,
                            type: 'warning'
                        });
            }
            console.log(this.task)
           this.saveData()
        },
        getHtmlPlainText(html_str) {
            //提取字符串中的文字
            let re = new RegExp('<[^<>]+>', 'g')
            if(!html_str){
                html_str=""
            }
            let text = html_str.replace(re, '')
            //或
            //var text = html_str.replace(/<[^<>]+>/g, "");
            return text
        },
        //设置备注内容
        setRemark(index,row,keyword){
            //console.log(index,row,keyword)
            this.currentIndex = index
            this.currentKeyword = keyword
            this.currentRemark = row[keyword].desc
            this.drawer = true
            console.log(index,this.currentRemark)
        },
        //下载
        download(save){
            this.page = 1
            this.search((res)=>{
                console.log(res.list,save)
                this.downloadTest(res.list,save)
            })
        },
        //下载数据,save=true则更新数据
        downloadTest(list,save){
            let columnData=[],testList=[]
                //console.log(list)
                list.forEach(item1=>{
                    let item=[],score=0,testItem={}
                    //1,工单基本信息
                    item.push(item1.start.split(" ")[0])
                    item.push(item1.staff)
                    item.push(item1.client)
                    //2，计算时间
                    let useTime = GetDateDiff(item1.start,item1.end,'minute')
                    //console.log(useTime)
                    item.push(item1.start)
                    item.push(item1.end)
                    item.push(useTime)
                    //设备数据
                    let list1 = item1.deviceList,
                    deviceNum=0
                    ,mouse = 0 ,
                    d_fly = 0,//苍蝇
                    d_mozzie=0,//蚊子
                    d_otherFly=0,//果蝇蛾蠓蚤蝇
                    d_treesFly = 0,//园林昆虫
                    d_otherBugs = 0,//其它昆虫
                    d_roach = 0,
                    rat_board = 0,//粘鼠板数量
                    cal_process = "",
                    flytrap_lamp = 0 //捕蝇灯数量
                    if(Array.isArray(list1)){
                        list1.forEach(item2=>{
                            //console.log(item2)
                            switch(item2.name){
                                case "粘鼠板":
                                    if(item2.images&&item2.images.length>=1){
                                        mouse += item2.bugs.mouse!='--'?item2.bugs.mouse*1:0
                                        d_roach += item2.bugs.roach!='--'?item2.bugs.roach*1:0
                                        d_fly += item2.bugs.fly!='--'?item2.bugs.fly*1:0
                                        d_mozzie += item2.bugs.mozzie!='--'?item2.bugs.mozzie*1:0
                                        d_otherFly += item2.bugs.otherFly!='--'?item2.bugs.otherFly*1:0
                                        rat_board++
                                    }
                                    break;
                                case "捕蝇灯":
                                    //console.log(item2)
                                    d_fly += item2.bugs.fly!='--'?item2.bugs.fly*1:0
                                    d_mozzie += item2.bugs.mozzie!='--'?item2.bugs.mozzie*1:0
                                    d_otherFly += item2.bugs.otherFly!='--'?item2.bugs.otherFly*1:0
                                    d_treesFly += item2.bugs.treesFly!='--'?item2.bugs.treesFly*1:0
                                    d_otherBugs += item2.bugs.otherBugs!='--'?item2.bugs.otherBugs*1:0
                                    d_roach += item2.bugs.roach!='--'?item2.bugs.roach*1:0
                                    flytrap_lamp++
                                    break;
                                case "蟑螂屋":
                                    d_roach += item2.bugs.roach!='--'?item2.bugs.roach*1:0
                                    break;
                                case "其它":
                                    break;
                            }
                            
                        })
                    }
                    //设备检查得分
                    if(Array.isArray(item1.deviceList)){
                        deviceNum = item1.deviceList.length
                        score+=deviceNum*4>40?40:deviceNum*4
                        cal_process+=`①检查设备得分（最高得分40）：${deviceNum}✖️4，得分[${deviceNum*4>40?40:deviceNum*4}]`
                    }
                    //处理措施
                    //console.log(item1)
                    let moreAction = item1.moreAction,_this=this,
                    disassembly=0,//拆机数量
                    descDisassembly=""//拆机描述
                    if(Array.isArray(moreAction)){
                        moreAction.forEach(item4=>{
                            let ar = _this.query(_this.DisassemblyList,item4.place)
                            disassembly+=ar.disassembly 
                            descDisassembly+=ar.descDisassembly?ar.descDisassembly+'（机）,':''
                        })
                    }
                    //拆机得分
                    score+=disassembly*2>6?6:disassembly*2
                    cal_process+=`②设备深度拆机得分（最高6分）：${disassembly}✖️2，得分[${disassembly*2>6?6:disassembly*2}]`
                    //全面检查虫害数据
                    let actionList = item1.actionList ,
                    actionLength = actionList.length,
                    bugsRiskDesc = "", //虫害描述
                    otherRiskDesc = '',//其它风险描述
                    flyNums = 0,
                    mouseNums = 0,
                    roachNums = 0 ,
                    otherNums = 0,//其它虫害
                    riskNum = 0, //其它风险项目数量
                    totalRiskNum = 0,//全部风险描述
                    bugsRiskNum = 0, //虫害风险数量
                    tianpinzhan = 0,//甜品店检查
                    kafei = 0,//麦咖啡
                    dilou = 0,//地漏
                    menchuang = 0,//门窗
                    datang = 0,//大堂/垃圾箱，消防栓，天花板
                    xishoutai = 0,//洗手台
                    fuwuqu = 0,//柜台
                    yinliaoji = 0 ,//饮料机
                    shutiaowei = 0,//薯条位
                    jianlu = 0,//煎炉,炸炉
                    caozuotai = 0,//oat/堡篮
                    zhibingji = 0,//制冰机
                    tangjiangji = 0,//糖浆机
                    guofenqu = 0,//果粉区
                    qingxichi = 0,//清洗池
                    geyouchi = 0,//隔油池
                    xiyiji = 0,//洗衣机
                    lajifang = 0,//垃圾房
                    jinglishi = 0,//经理室
                    xiuxishi = 0,//休息室
                    kufang = 0,//库房
                    qitaquyu=0,//其它区域
                    waihuanjing = 0//外环境
                    
                    //记分：1,全面检查记分
                    score+=actionLength*2>20?20:actionLength*2
                    cal_process+=`③全面检查数量（最高20分）：${actionLength}✖️2,得分[${actionLength*2>20?20:actionLength*2}]`

                    actionList.forEach(item3=>{
                        //console.log(item3)
                        //统计全面检查地点
                        if(item3.firstPlace.indexOf("大堂")!==-1){
                            //大堂
                            if(item3.secondPlace.indexOf("垃圾箱")!==-1){
                                //1.垃圾箱
                                datang++;
                            }else if(item3.secondPlace.indexOf("垃圾房")!==-1){
                                //2.垃圾房(地漏/卫生)
                                lajifang++;
                                if(item3.risk.indexOf("地漏")!==-1){
                                    dilou++;
                                }
                            }else if(item3.secondPlace.indexOf("对外门")!==-1){
                                //3.对外门
                                menchuang++
                            }else if(item3.secondPlace.indexOf("窗户")!==-1){
                                //4.窗户
                                menchuang++
                            }else if(item3.secondPlace.indexOf("洗手")!==-1){
                                //5.洗手间
                                xishoutai++
                                if(item3.risk.indexOf("地漏")!==-1){
                                    dilou++;
                                }
                            }else if(item3.secondPlace.indexOf("消防栓")!==-1){
                                //6.消防栓
                                datang++
                            }else if(item3.secondPlace.indexOf("地脚线")!==-1){
                                //7.地脚线
                                datang++
                            }
                        }else if(item3.firstPlace.indexOf("甜品站")!==-1){
                            tianpinzhan++;
                            if(item3.risk.indexOf("地漏")!==-1){
                                dilou++;
                            }
                        }else if(item3.firstPlace.indexOf("麦咖啡")!==-1){
                            kafei++;
                            if(item3.risk.indexOf("地漏")!==-1){
                                dilou++;
                            }
                        }else if(item3.firstPlace.indexOf("经理室")!==-1){
                            jinglishi++
                        }else if(item3.firstPlace.indexOf("外围环境")!==-1){
                            waihuanjing++
                        }else if(item3.firstPlace.indexOf("库房")!==-1){
                            kufang++
                        }else if(item3.firstPlace.indexOf("产区")!==-1){
                            if(item3.secondPlace.indexOf("薯条")!==-1){
                                shutiaowei++
                            }else if(item3.secondPlace.indexOf("炸炉")!==-1||item3.secondPlace.indexOf("煎炉")!==-1){
                                jianlu++
                            }else if(item3.secondPlace.indexOf("冷冻柜")!==-1||item3.secondPlace.indexOf("汉堡制作区")!==-1){
                                shutiaowei++
                            }else if(item3.secondPlace.indexOf("制冰机")!==-1){
                                zhibingji++
                            }else if(item3.risk.indexOf("天花板")!==-1){
                                //天花板缝隙
                                qitaquyu++
                            }else if(item3.risk.indexOf("地漏")!==-1){
                                //地漏
                                dilou++
                            }else{
                                qitaquyu++
                            }
                        }else if(item3.firstPlace.indexOf("后区")!==-1){
                            if(item3.secondPlace.indexOf("裹粉位")!==-1){
                                guofenqu++
                            }else if(item3.secondPlace.indexOf("冷库")!==-1){
                                qitaquyu++
                            }else if(item3.secondPlace.indexOf("洗手池")!==-1){
                                xishoutai++
                            }else if(item3.secondPlace.indexOf("清洗水槽")!==-1){
                                qingxichi++
                            }else if(item3.secondPlace.indexOf("隔油池")!==-1){
                                geyouchi++
                            }else if(item3.secondPlace.indexOf("洗衣机")!==-1){
                                xiyiji++
                            }else if(item3.secondPlace.indexOf("糖浆储存")!==-1){
                                tangjiangji++
                            }else if(item3.secondPlace.indexOf("二氧化碳")!==-1){
                                tangjiangji++
                            }else if(item3.secondPlace.indexOf("后门")!==-1){
                                qitaquyu++
                            }else{
                                qitaquyu++
                            }
                        }else{
                            qitaquyu++
                        }

                        //虫害风险
                        let bugsNums=0
                        if(item3.risk!="无"&&item3.risk!="--"&&item3.risk){
                            totalRiskNum++;
                            if(item3.nums=="4只以上"){
                                bugsNums=4
                            }else if(item3.nums!="无"){
                                bugsNums = item3.nums*1
                            }
                            switch(item3.risk){
                                    case "飞虫":
                                        bugsRiskNum++
                                        flyNums+=bugsNums
                                bugsRiskDesc+="("+bugsRiskNum+")"+item3.firstPlace+'>'+item3.secondPlace+" ："+item3.remark
                                        break;
                                    case "鼠类":
                                        bugsRiskNum++
                                        mouseNums +=bugsNums
                                bugsRiskDesc+="("+bugsRiskNum+")"+item3.firstPlace+'>'+item3.secondPlace+" ："+item3.remark
                                        break;
                                    case "蟑螂":
                                        bugsRiskNum++
                                        roachNums +=bugsNums
                                bugsRiskDesc+="("+bugsRiskNum+")"+item3.firstPlace+'>'+item3.secondPlace+" ："+item3.remark
                                        break;
                                    case "其他虫害":
                                        bugsRiskNum++
                                        otherNums+=bugsNums
                                bugsRiskDesc+="("+bugsRiskNum+")"+item3.firstPlace+'>'+item3.secondPlace+" ："+item3.remark
                                        break;
                                    default:
                                riskNum++;
                                otherRiskDesc+="("+riskNum+")"+item3.firstPlace+'>'+item3.secondPlace+" ："+item3.remark
                                        break;
                                    
                                }
                        }
                        
                    })
                    //风险描述加分
                    score+=totalRiskNum*2>20?20:totalRiskNum*2
                    cal_process+=`④全面检查中发现风险项（最高20分）：${totalRiskNum}*2，得分[${totalRiskNum*2>20?20:totalRiskNum*2}]，`

                    //记分：2,虫害数量记分
                    let beforeScore = score
                    let mouseScore = mouse*5,roachScore = roachNums*5,flyScore = flyNums*2,otherScore = otherNums*2
                    score+=mouseScore>10?10:mouseScore
                    score+=roachScore>10?10:roachScore 
                    score+=flyScore>10?10:flyScore
                    score+=otherScore>10?5:otherScore
                    //记分；3.设备检查分
                    // score+=rat_board*5>20?20:rat_board*5 
                    // score+=flytrap_lamp*5>20?20:flytrap_lamp*5

                    cal_process+=`⑤发现虫害活体（最高35分）：鼠${mouse}✖️2+蟑螂${roachNums}✖️5+飞虫 ${flyNums}✖️2+其它虫害${otherNums}✖️2
                    ，得分[${score-beforeScore}]`
                    //得分
                    item.push(score)


                    //虫害风险等级
                    item.push(item1.mouseRisk)
                    item.push(item1.roachRisk)
                    item.push(item1.flyRisk)
                    item.push(item1.otherRisk)

                    //鼠
                    if(mouseNums!=0){
                        mouse+=mouseNums
                    }
                    item.push(mouse)
                    //蟑螂
                    item.push(roachNums)
                    //飞虫
                    item.push(flyNums)
                    //其它虫害
                    item.push(otherNums)

                    //全面检查虫害风险内容
                    item.push(bugsRiskDesc)
                    //其它风险描述
                    item.push(otherRiskDesc)
                    //风险数量
                    item.push(bugsRiskNum)
                    item.push(riskNum)
                    //捕蝇灯，粘鼠板数
                    item.push(rat_board)
                    item.push(flytrap_lamp)

                    //填充区域检查数量
                    item.push(tianpinzhan)
                    item.push(kafei)
                    item.push(dilou)
                    item.push(menchuang)
                    item.push(datang)
                    item.push(xishoutai)
                    item.push(fuwuqu)
                    item.push(yinliaoji)
                    item.push(shutiaowei)
                    item.push(jianlu)
                    item.push(caozuotai)
                    item.push(zhibingji)
                    item.push(tangjiangji)
                    item.push(guofenqu)
                    item.push(qingxichi)
                    item.push(geyouchi)
                    item.push(xiyiji)
                    item.push(qitaquyu)
                    item.push(lajifang)
                    item.push(jinglishi)
                    item.push(xiuxishi)
                    item.push(kufang)
                    item.push(waihuanjing)

                    //设备蟑螂
                    item.push(d_roach)
                    //设备飞虫
                    item.push(d_fly) //苍蝇
                    item.push(d_mozzie) //蚊子
                    item.push(d_otherFly) //蚤蝇
                    item.push(d_treesFly) //园林虫害
                    item.push(d_otherBugs) //其它昆虫
                    //拆机数量
                    item.push(disassembly)
                    item.push(descDisassembly)

                    // console.log(item)
                    columnData.push(item)
                    let mscore = item1.score||score
                    testItem={
                        mid:item1.id,
                        date:item1.start.split(" ")[0],
                        useTime:useTime,
                        disassembly,
                        descDisassembly,
                        staff:item1.staff,
                        score:save?score:mscore,
                        cal_process
                    }
                    //console.log(cal_process)
                    testList.push(testItem)
                })
                //console.log(columnData)
                //下载数据导excel
                let columnTitle=['日期','工程师','客户','开始时间','结束时间','用时','得分','鼠类风险','蟑螂风险','飞虫风险','其它虫害风险','鼠迹'
                ,'蟑螂','飞虫','其它虫害','虫害情况描述','风险问题描述','虫害发现的描述数量','风险问题描述数量','捕蝇灯检查数','粘鼠板检查数','甜品店检查点数','麦咖啡检查点数','地漏下水口检查数',
                '对外门窗及附近','大堂垃圾箱','洗手台','服务区','饮料机设备','薯条位','煎炸炉区','操作台','制冰机区','糖浆机区','裹粉位','清洗池区',
                '隔油池','洗衣机区','其它区域','垃圾房','经理室','休息室','库房','外环境','设备蟑螂数量'
                ,'设备-苍蝇','设备-蚊子','设备-蚤蝇,蛾蠓，果蝇','设备-园林虫害','设备-其它虫害','拆机数量','拆机描述']
                if(!save){
                    exportExcel(columnTitle, columnData, "客户数据导出明细表");
                }
                //保存到数据库
                if(save===true){
                    this.saveMcTest(testList,0,save)
                }
        },
        saveMcTest(list,index,update){
            //console.log(list.length,index,list.length>(index+1))
            if(list.length<(index+1)){
                //上传完成
                this.$message({
                    message:"成功保存了 "+list.length+'条数据！',
                    type: 'success'
                });
                return
            }
            let params = list[index]
            params.method = "mc_test"
            params.update = update||''
            this.loading = true
            //console.log(params.cal_process)
            //console.log(update)
            this.$ajax.post(this.API.api,params).then(()=>{
                 this.loading = false
                 //console.log(res)
                 index++
                 this.saveMcTest(list,index,update)  
            }).catch(err=>{
                     //console.log(err)
                        this.loading = false
                        this.$message({
                            message: err.msg?err.msg:err,
                            type: 'warning'
                        });
            })
        },
        //js模糊查找数组内元素
        query(list, keyword) {
            let disassembly=0,descDisassembly=""
            for(let i=0;i<list.length;i++){
                if(keyword.indexOf(list[i])!==-1){
                    //找到设备
                    disassembly++
                    descDisassembly+=list[i]
                    break;
                }
            }
            return {
                disassembly,
                descDisassembly
            }
        },
        //删除
        deleteData(id){
            this.$confirm(this.CONST.DEL_CONFIRM, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    let params = {
                        method:"delTestReportData",
                        id
                    }
                    this.$ajax.post(this.API.api,params).then(()=>{
                        this.loading = false
                        this.$message({
                            message:"删除成功！ ",
                            type: 'success'
                        });
                        this.refresh()
                    }).catch(err=>{
                        console.log(err)
                        this.loading = false
                        this.$message({
                            message: err.msg?err.msg:err,
                            type: 'warning'
                        });
                    })
                }).catch(()=>{

                })
        },
        search(callback,test){
            //console.log('搜索')
            if(this.page>this.totalPage){
                console.log(this.page,this.totalPage)
                this.$message({
                    message: "已全部加载！",
                    type: 'warning'
                });
                return
            }
            let params = {
                 method:"getMCReport",
                 page:!callback?this.page:1,
                 pageSize:!callback?this.pageSize:10000,
                 staff:this.staff,
                 start:formatTime(this.timeArr[0]),
                 end:formatTime(this.timeArr[1]),
                 risk:this.risk,
                 client:this.client,
                 riskType:this.riskType
             }
             this.loading = true
             console.log(params)
            this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false
                console.log(data)
                
                data.list.forEach((item)=>{
                        item.actionList=JSON.parse(item.actionList)
                        item.lastAdList = JSON.parse(item.lastAdivceList)
                        item.moreAction = JSON.parse(item.moreAction)
                        item.deviceList = JSON.parse(item.deviceList)
                    })
                    if(callback){
                        return callback(data)
                    }
                    this.list = data.list 
                    this.totalPage = data.totalPage 
                    if(data.list.length>=1){
                        this.page++
                    }
                    if(test){
                        //自动计算最新一个的评分
                        console.log(test,'计算评分')
                        let mslist=[this.list[0]]
                        this.downloadTest(mslist,true)
                    }
                
            }).catch(err=>{
                console.log(err)
                console.log(err.data)
                this.loading = false
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        //添加后更新数据
        refresh(test){
            //console.log(test)
            this.page = 1
            this.totalPage = 1
            this.search(null,test==true?true:false)
        },
        //保存数据
        saveData(){
            if(!this.addTaskRule){
                this.$message({
                    message: "权限不足！    ",
                    type: 'warning'
                });
                return
            }
           let params = JSON.parse(JSON.stringify(this.task));
           params.actionList=JSON.stringify(params.actionList)
           params.lastAdivceList = JSON.stringify(params.lastAdivceList)
           params.deviceList = JSON.stringify(params.deviceList)
           params.moreAction = JSON.stringify(params.moreAction)
           params.method="saveMCReport"
           params.flyRisk = params.risk.flyRisk 
           params.mouseRisk = params.risk.mouseRisk
           params.roachRisk = params.risk.roachRisk
           params.otherRisk = params.risk.otherRisk
            this.$ajax.post(this.API.api,params).then((data)=>{
                this.loading = false
                console.log(data)
                this.task={}
                this.content=""
                this.drawer=false
                this.$message({
                    message: data.msg||'添加成功！',
                    type: 'success'
                });
                this.refresh(true)
            }).catch(err=>{
                console.log(err)
                this.loading = false
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        cellClick(row){
            let score = 0
            for(var key in row){
                //console.log(key)
                if(row[key]&&row[key].type=="num"){
                    score += parseInt(row[key].data)
                }
            }
            row.score = score
            console.log(row.score)
        },
        //输入变化时更新得分
        changeInput(index,row){
            console.log(index,row)
            row.isEditor = false
            this.$set(this.data,index,row);
            row.isEditor = true
        },
        clientSelect(){

        },
        clientSearch(queryString,cb){
            let params = {
                 method:"getClientList",
                 client:queryString,
                 page:1,
                 pageSize:10
             }
            this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false
                console.log(data)
                if(data.list){
                    let list=[]
                    data.list.forEach(item=>{
                        list.push({
                            value:item.shortname
                        })
                    })
                    cb(list)
                }
                
            }).catch(err=>{
                console.log(err)
                this.loading = false
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        staffSearch(queryString, cb){
            let params = {
                 method:"getStaffList",
                 keys:queryString,
                 page:1,
                 pageSize:10
             }
            this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false
                //console.log(data)
                if(data.list){
                    let list=[]
                    data.list.forEach(item=>{
                        list.push({
                            value:item.username
                        })
                    })
                    cb(list)
                }
                
            }).catch(err=>{
                console.log(err)
                this.loading = false
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        staffSelect(){

        }
    }
}
</script>
<style lang="less">
.titleSpan{
    font-size: 26px;
    margin:0 10px;
}
span.btn{
    margin-left: 10px;
    cursor: pointer;
}
span.btn:hover{
    color:#39f;
}
span.remarked{
    color:#39f;
}
span.view{
    color:#39f;
    cursor: pointer;
    font-size: 0.8em;
}
span.view:hover{
    color:rgb(255, 51, 51);
}
.con-box{
    padding:5px 15px;
}
.i-btn{
    margin:0 5px;
    cursor:pointer;
}
.i-btn:hover{
    color:rgb(192, 5, 5);
}
</style>